
import {
  computed, defineComponent, watchEffect, ref, watch,
} from '@vue/composition-api';

import { isObject } from 'lodash';
// @ts-ignore
import Cite from 'citation-js';
import {
  typeWithCardinality, valueCardinality, fieldDisplayName,
} from '@/util';
import {
  api, StudySearchResults, DOI, Condition,
} from '@/data/api';
import { setUniqueCondition, setConditions } from '@/store';
import { useRouter } from '@/use/useRouter';
import AppBanner from '@/components/AppBanner.vue';
import AttributeItem from '@/components/Presentation/AttributeItem.vue';
import IndividualTitle from '@/views/IndividualResults/IndividualTitle.vue';
import TeamInfo from '@/components/TeamInfo.vue';
/**
 * Override citations for certain DOIs
 */
 interface CitationOverridesType {
  [key: string]: string;
}
const CitationOverrides: CitationOverridesType = {
  '10.46936/10.25585/60000017': 'Doktycz, M. (2020) BioScales - Defining plant gene function and its connection to ecosystem nitrogen and carbon cycling [Data set]. DOE Joint Genome Institute. https://doi.org/10.46936/10.25585/60000017',
};
const GoldStudyLinkBase = 'https://gold.jgi.doe.gov/study?id=';

export default defineComponent({

  components: {
    AppBanner,
    AttributeItem,
    IndividualTitle,
    TeamInfo,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const dois = ref({
      awardDois: [] as DOI[],
      publicationDois: [] as DOI[],
      datasetDois: [] as DOI[],
      massiveDois: [] as DOI[],
      essDiveDois: [] as DOI[],
    });

    const item = ref(null as StudySearchResults | null);
    const parentStudies = ref([]as StudySearchResults[]);

    watch(item, () => {
      if (item.value?.part_of) {
        item.value.part_of.forEach((id: string) => {
          if (!parentStudies.value.some((study) => study.id === id)) {
            api.getStudy(id).then((b) => {
              parentStudies.value.push(b);
            });
          }
        });
      } else {
        parentStudies.value = [];
      }
    });

    watchEffect(() => {
      api.getStudy(props.id).then((b) => { item.value = b; });
    });

    const studyData = computed(() => Object.values(item)
      .map((val) => ({
        ...val,
        sample_count: val?.children?.reduce((acc: number, child: StudySearchResults) => acc + child.sample_count, val.sample_count),
      }))[0]);

    const displayFields = computed(() => {
      if (item.value === null) {
        return [];
      }
      return Object.entries(item.value).filter(([field, value]) => {
        if (['name', 'description'].includes(field)) {
          return false;
        }
        return !isObject(value);
      });
    });

    const goldLinks = computed(() => {
      if (!item.value?.gold_study_identifiers && !item.value?.open_in_gold) {
        return new Set();
      }
      const links = new Set();
      if (item.value.open_in_gold) {
        links.add(item.value.open_in_gold);
      }
      if (item.value.gold_study_identifiers) {
        item.value.gold_study_identifiers.forEach((identifier: string) => {
          if (identifier.toLowerCase().startsWith('gold:')) {
            links.add(GoldStudyLinkBase + identifier.substring(5));
          }
        });
      }
      return links;
    });

    function relatedTypeDescription(relatedType: string) {
      if (item.value) {
        const n = valueCardinality(item.value[`${relatedType}_id`]);
        return `${n} ${typeWithCardinality(relatedType, n)}`;
      }
      return '';
    }

    function openLink(url: string) {
      window.open(url, '_blank');
    }

    function formatAPA(citation: any) {
      return citation.format('bibliography', {
        format: 'text',
        template: 'apa',
        lang: 'en-US',
      });
    }

    const router = useRouter();

    function seeOmicsForStudy(omicsType = '') {
      setUniqueCondition(
        ['study_id', 'omics_type'],
        ['study', 'omics_processing'],
        [{
          value: props.id,
          table: 'study',
          op: '==',
          field: 'id',
        }, {
          value: omicsType,
          table: 'omics_processing',
          field: 'omics_type',
          op: '==',
        }],
      );
      /* @ts-ignore */
      router.go({ name: 'Search' });
    }

    function seeStudyInContext(item: StudySearchResults) {
      const conditions: Condition[] = [{
        op: '==',
        table: 'study',
        field: 'study_id',
        value: props.id,
      }];
      if (item.children.length > 0) {
        item.children.forEach((child: StudySearchResults) => {
          conditions.push({
            value: child.id,
            table: 'study',
            field: 'study_id',
            op: '==',
          });
        });
      }
      setConditions(conditions, true);
    }

    watch(item, async (_item) => {
      const doiMap = _item?.doi_map;
      if (doiMap) {
        dois.value.awardDois = [];
        dois.value.publicationDois = [];
        dois.value.datasetDois = [];
        dois.value.awardDois = Object.values(doiMap)
          .filter((doi) => doi.category === 'award_doi')
          .map((doi) => [{
            cite: CitationOverrides[doi.info.DOI] || formatAPA(new Cite(doi.info.DOI)),
            id: doi.info.DOI,
            provider: doi.provider,
          }]).flat();
        dois.value.datasetDois = Object.values(doiMap)
          .filter((doi) => doi.category === 'dataset_doi')
          .map((doi) => [{
            cite: CitationOverrides[doi.info.DOI] || formatAPA(new Cite(doi.info.DOI)),
            id: doi.info.DOI,
            provider: doi.provider,
          }]).flat();
        dois.value.publicationDois = Object.values(doiMap)
          .filter((doi) => doi.category === 'publication_doi')
          .map((doi) => [{
            cite: CitationOverrides[doi.info.DOI] || formatAPA(new Cite(doi.info.DOI)),
            id: doi.info.DOI,
            provider: doi.provider,
          }]).flat();
      }
    });
    return {
      CitationOverrides,
      GoldStudyLinkBase,
      goldLinks,
      data: dois,
      item,
      studyData,
      displayFields,
      /* Methods */
      seeOmicsForStudy,
      relatedTypeDescription,
      openLink,
      formatAPA,
      typeWithCardinality,
      fieldDisplayName,
      seeStudyInContext,
      parentStudies,
      images: {
        // eslint-disable-next-line global-require
        gold: require('@/assets/GOLD.png'),
        // eslint-disable-next-line global-require
        ess: require('@/assets/ESS.png'),
        // eslint-disable-next-line global-require
        massive: require('@/assets/massive.png'),
      },
    };
  },
});
