
import { computed, defineComponent } from '@vue/composition-api';
import { stateRefs } from '@/store';

export default defineComponent({
  setup() {
    const message = stateRefs.bannerMessage;
    const title = stateRefs.bannerTitle;
    const showAppBanner = computed(() => message.value || title.value);
    return { showAppBanner, message, title };
  },
});
