
import {
  computed, defineComponent, ref, nextTick, watch, onMounted, shallowRef,
} from '@vue/composition-api';
import {
  clamp, flattenDeep, has, sum,
} from 'lodash';
import { read, writeFile, utils } from 'xlsx';
import { api } from '@/data/api';
import { urlify } from '@/data/utils';
import useRequest from '@/use/useRequest';

import {
  HarmonizerApi,
  HARMONIZER_TEMPLATES,
  EMSL,
  JGI_MG,
  JGT_MT,
  JGI_MG_LR,
} from './harmonizerApi';
import {
  packageName,
  sampleData,
  status,
  submit,
  incrementalSaveRecord,
  templateList,
  mergeSampleData,
  hasChanged,
  tabsValidated,
  submissionStatus,
  canEditSampleMetadata,
  isOwner,
  isTestSubmission,
} from './store';
import ContactCard from '@/views/SubmissionPortal/Components/ContactCard.vue';
import FindReplace from './Components/FindReplace.vue';
import SubmissionStepper from './Components/SubmissionStepper.vue';
import SubmissionDocsLink from './Components/SubmissionDocsLink.vue';
import SubmissionPermissionBanner from './Components/SubmissionPermissionBanner.vue';
import { APP_HEADER_HEIGHT } from '@/components/Presentation/AppHeader.vue';
import { stateRefs } from '@/store';

interface ValidationErrors {
  [error: string]: [number, number][],
}

const ColorKey = {
  required: {
    label: 'Required field',
    color: 'yellow',
  },
  recommended: {
    label: 'Recommended field',
    color: 'plum',
  },
  invalidCell: {
    label: 'Invalid cell',
    color: '#ffcccb',
  },
  emptyCell: {
    label: 'Empty invalid cell',
    color: '#ff91a4',
  },
};

const HELP_SIDEBAR_WIDTH = '300px';

const EXPORT_FILENAME = 'nmdc_sample_export.xlsx';

const SAMP_NAME = 'samp_name';
const SOURCE_MAT_ID = 'source_mat_id';
const ANALYSIS_TYPE = 'analysis_type';

// controls which field is used to merge data from different DH views
const SCHEMA_ID = SAMP_NAME;

// used in determining which rows are shown in each view
const TYPE_FIELD = ANALYSIS_TYPE;

// TODO: should this be derived from schema?
const COMMON_COLUMNS = [SAMP_NAME, SOURCE_MAT_ID, ANALYSIS_TYPE];

const ALWAYS_READ_ONLY_COLUMNS = [
  'dna_seq_project',
  'rna_seq_project',
  'dna_samp_id',
  'rna_samp_id',
  'rna_seq_project_pi',
  'dna_seq_project_pi',
  'dna_project_contact',
  'rna_project_contact',
  'proposal_rna',
  'proposal_dna',
  'rna_seq_project_name',
  'dna_seq_project_name',
];

export default defineComponent({
  components: {
    ContactCard,
    FindReplace,
    SubmissionStepper,
    SubmissionDocsLink,
    SubmissionPermissionBanner,
  },

  setup(_, { root }) {
    const { user } = stateRefs;

    const harmonizerElement = ref();
    const harmonizerApi = new HarmonizerApi();
    const jumpToModel = ref();
    const highlightedValidationError = ref(0);
    const validationActiveCategory = ref('All Errors');
    const columnVisibility = ref('all');
    const sidebarOpen = ref(true);
    const invalidCells = shallowRef({} as Record<string, Record<number, Record<number, string>>>);

    const activeTemplateKey = ref(templateList.value[0]);
    const activeTemplate = ref(HARMONIZER_TEMPLATES[activeTemplateKey.value]);
    const activeTemplateData = computed(() => {
      if (!activeTemplate.value.sampleDataSlot) {
        return [];
      }
      return sampleData.value[activeTemplate.value.sampleDataSlot] || [];
    });

    const submitDialog = ref(false);

    const snackbar = ref(false);
    const importErrorSnackbar = ref(false);
    const notImportedWorksheetNames = ref([] as string[]);

    watch(activeTemplate, () => {
      // WARNING: It's important to do the column settings update /before/ data. Otherwise,
      // columns will not be rendered with the correct width.
      harmonizerApi.setColumnsReadOnly(ALWAYS_READ_ONLY_COLUMNS);

      // If the environment tab selected is a mixin it should be readonly
      const environmentList = templateList.value.filter((t) => HARMONIZER_TEMPLATES[t].status === 'mixin');
      if (environmentList.includes(activeTemplateKey.value)) {
        harmonizerApi.setColumnsReadOnly(COMMON_COLUMNS);
        harmonizerApi.setMaxRows(activeTemplateData.value.length);
      }
      harmonizerApi.loadData(activeTemplateData.value);
      harmonizerApi.setInvalidCells(invalidCells.value[activeTemplateKey.value] || {});
    });

    const validationErrors = computed(() => {
      const remapped: ValidationErrors = {};
      const invalid: Record<number, Record<number, string>> = invalidCells.value[activeTemplateKey.value] || {};
      if (Object.keys(invalid).length) {
        remapped['All Errors'] = [];
      }
      Object.entries(invalid).forEach(([row, rowErrors]) => {
        Object.entries(rowErrors).forEach(([col, errorText]) => {
          const entry: [number, number] = [parseInt(row, 10), parseInt(col, 10)];
          const issue = errorText || 'Other Validation Error';
          if (has(remapped, issue)) {
            remapped[issue].push(entry);
          } else {
            remapped[issue] = [entry];
          }
          remapped['All Errors'].push(entry);
        });
      });
      return remapped;
    });

    const validationErrorGroups = computed(() => Object.keys(validationErrors.value));

    const validationTotalCounts = computed(() => Object.fromEntries(
      Object.entries(invalidCells.value).map(([template, cells]) => ([
        template,
        sum(Object.values(cells).map((row) => Object.keys(row).length)),
      ])),
    ));

    const saveRecordRequest = useRequest();
    const saveRecord = () => saveRecordRequest.request(() => incrementalSaveRecord(root.$route.params.id));

    const onDataChange = async () => {
      hasChanged.value += 1;
      const data = harmonizerApi.exportJson();
      mergeSampleData(activeTemplate.value.sampleDataSlot, data);
      saveRecord(); // This is a background save that we intentionally don't wait for
      tabsValidated.value[activeTemplateKey.value] = false;
    };
    const { request: schemaRequest, loading: schemaLoading } = useRequest();
    onMounted(async () => {
      const [schema, goldEcosystemTree] = await schemaRequest(() => Promise.all([
        api.getSubmissionSchema(),
        api.getGoldEcosystemTree(),
      ]));
      const r = document.getElementById('harmonizer-root');
      if (r && schema) {
        await harmonizerApi.init(r, schema, activeTemplate.value.schemaClass, goldEcosystemTree);
        await nextTick();
        harmonizerApi.loadData(activeTemplateData.value);
        harmonizerApi.addChangeHook(onDataChange);
        if (!canEditSampleMetadata()) {
          harmonizerApi.setTableReadOnly();
        }
      }
    });

    async function jumpTo({ row, column }: { row: number; column: number }) {
      harmonizerApi.jumpToRowCol(row, column);
      await nextTick();
      jumpToModel.value = null;
    }

    function focus() {
      window.focus();
    }

    function errorClick(index: number) {
      const currentSeries = validationErrors.value[validationActiveCategory.value];
      highlightedValidationError.value = clamp(index, 0, currentSeries.length - 1);
      const currentError = currentSeries[highlightedValidationError.value];
      harmonizerApi.jumpToRowCol(currentError[0], currentError[1]);
    }

    async function validate() {
      const data = harmonizerApi.exportJson();
      mergeSampleData(activeTemplate.value.sampleDataSlot, data);
      const result = await harmonizerApi.validate();
      const valid = Object.keys(result).length === 0;
      if (!valid && !sidebarOpen.value) {
        sidebarOpen.value = true;
      }

      invalidCells.value = {
        ...invalidCells.value,
        [activeTemplateKey.value]: result,
      };
      saveRecord(); // This is a background save that we intentionally don't wait for
      if (valid === false) {
        errorClick(0);
      }
      tabsValidated.value = {
        ...tabsValidated.value,
        [activeTemplateKey.value]: valid,
      };

      snackbar.value = Object.values(tabsValidated.value).every((value) => value);
    }

    const canSubmit = computed(() => {
      let allTabsValid = true;
      Object.values(tabsValidated.value).forEach((value) => {
        allTabsValid = allTabsValid && value;
      });
      return allTabsValid && isOwner();
    });

    const fields = computed(() => flattenDeep(Object.entries(harmonizerApi.schemaSectionColumns.value)
      .map(([sectionName, children]) => Object.entries(children).map(([columnName, column]) => {
        const val = {
          text: columnName ? `  ${columnName}` : sectionName,
          value: {
            sectionName, columnName, column, row: 0,
          },
        };
        return val;
      }))));

    const validationItems = computed(() => validationErrorGroups.value.map((errorGroup) => {
      const errors = validationErrors.value[errorGroup];
      return {
        text: `${errorGroup} (${errors.length})`,
        value: errorGroup,
      };
    }));

    watch(validationActiveCategory, () => errorClick(0));
    watch(columnVisibility, () => {
      harmonizerApi.changeVisibility(columnVisibility.value);
    });

    const selectedHelpDict = computed(() => {
      if (harmonizerApi.selectedColumn.value) {
        return harmonizerApi.getHelp(harmonizerApi.selectedColumn.value);
      }
      return null;
    });

    const { request: submitRequest, loading: submitLoading, count: submitCount } = useRequest();
    const doSubmit = () => submitRequest(async () => {
      const data = await harmonizerApi.exportJson();
      mergeSampleData(activeTemplate.value.sampleDataSlot, data);
      await submit(root.$route.params.id, submissionStatus.SubmittedPendingReview);
      submitDialog.value = false;
    });

    function rowIsVisibleForTemplate(row: Record<string, any>, templateKey: string) {
      const environmentKeys = templateList.value.filter((t) => HARMONIZER_TEMPLATES[t].status === 'published');
      if (environmentKeys.includes(templateKey)) {
        return true;
      }
      const row_types = row[TYPE_FIELD];
      if (!row_types) {
        return false;
      }
      if (templateKey === EMSL) {
        return row_types.includes('metaproteomics')
          || row_types.includes('metabolomics')
          || row_types.includes('natural organic matter');
      }
      if (templateKey === JGI_MG) {
        return row_types.includes('metagenomics');
      }
      if (templateKey === JGI_MG_LR) {
        return row_types.includes('metagenomics_long_read');
      }
      if (templateKey === JGT_MT) {
        return row_types.includes('metatranscriptomics');
      }
      return false;
    }

    function synchronizeTabData(templateKey: string) {
      const environmentKeys = templateList.value.filter((t) => HARMONIZER_TEMPLATES[t].status === 'published');
      if (environmentKeys.includes(templateKey)) {
        return;
      }
      const nextData = { ...sampleData.value };
      const templateSlot = HARMONIZER_TEMPLATES[templateKey].sampleDataSlot;

      const environmentSlots = templateList.value
        .filter((t) => HARMONIZER_TEMPLATES[t].status === 'published')
        .map((t) => HARMONIZER_TEMPLATES[t].sampleDataSlot);

      if (!templateSlot || !environmentSlots) {
        return;
      }

      // ensure the necessary keys exist in the data object
      environmentSlots.forEach((slot) => {
        if (!nextData[slot as string]) {
          nextData[slot as string] = [];
        }
      });

      if (!nextData[templateSlot]) {
        nextData[templateSlot] = [];
      }

      // add/update any rows from the environment tabs to the active tab if they apply and if
      // they aren't there already.
      environmentSlots.forEach((environmentSlot) => {
        nextData[environmentSlot as string].forEach((row) => {
          const rowId = row[SCHEMA_ID];

          const existing = nextData[templateSlot] && nextData[templateSlot].find((r) => r[SCHEMA_ID] === rowId);
          if (!existing && rowIsVisibleForTemplate(row, templateKey)) {
            const newRow = {} as Record<string, any>;
            COMMON_COLUMNS.forEach((col) => {
              newRow[col] = row[col];
            });
            nextData[templateSlot].push(newRow);
          }
          if (existing) {
            COMMON_COLUMNS.forEach((col) => {
              existing[col] = row[col];
            });
          }
        });
      });
      // remove any rows from the active tab if they were removed from the environment tabs
      // or no longer apply to the active tab
      if (nextData[templateSlot].length > 0) {
        nextData[templateSlot] = nextData[templateSlot].filter((row) => {
          if (!rowIsVisibleForTemplate(row, templateKey)) {
            return false;
          }
          const rowId = row[SCHEMA_ID];
          return environmentSlots.some((environmentSlot) => {
            const environmentRow = nextData[environmentSlot as string].findIndex((r) => r[SCHEMA_ID] === rowId);
            return environmentRow >= 0;
          });
        });
      }
      sampleData.value = nextData;
    }

    async function downloadSamples() {
      templateList.value.forEach((templateKey) => {
        synchronizeTabData(templateKey);
      });

      const workbook = utils.book_new();
      templateList.value.forEach((templateKey) => {
        const template = HARMONIZER_TEMPLATES[templateKey];
        if (!template.sampleDataSlot || !template.schemaClass) {
          return;
        }
        const worksheet = utils.json_to_sheet([
          harmonizerApi.getHeaderRow(template.schemaClass),
          ...HarmonizerApi.flattenArrayValues(sampleData.value[template.sampleDataSlot]),
        ], {
          skipHeader: true,
        });
        utils.book_append_sheet(workbook, worksheet, template.excelWorksheetName || template.displayName);
      });
      writeFile(workbook, EXPORT_FILENAME, { compression: true });
    }

    function showOpenFileDialog() {
      document.getElementById('tsv-file-select')?.click();
    }

    function openFile(file: File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event == null || event.target == null) {
          return;
        }
        const workbook = read(event.target.result);
        const imported = {} as Record<string, any>;
        const notImported = [] as string[];
        Object.entries(workbook.Sheets).forEach(([name, worksheet]) => {
          const template = Object.values(HARMONIZER_TEMPLATES).find((template) => (
            template.excelWorksheetName === name || template.displayName === name
          ));
          if (!template || !template.sampleDataSlot || !template.schemaClass) {
            notImported.push(name);
            return;
          }

          // The spreadsheet has slot names as the header row. So `sheet_to_json` will produce array
          // of objects with slot names as keys. But we want the imported data to be keyed on slot
          // IDs. This code reads the worksheet data and remaps the keys from slot names to IDs.
          const slotIdToNameMap = harmonizerApi.getHeaderRow(template.schemaClass);
          const slotNameToIdMap = Object.fromEntries(Object.entries(slotIdToNameMap).map(([k, v]) => [v, k]));
          const worksheetData: Record<string, string>[] = utils.sheet_to_json(worksheet);
          const remappedData = worksheetData.map((row) => Object.fromEntries(Object.entries(row)
            .filter(([slotName]) => slotNameToIdMap[slotName] !== undefined)
            .map(([slotName, value]) => [slotNameToIdMap[slotName], value])));

          imported[template.sampleDataSlot] = harmonizerApi.unflattenArrayValues(
            remappedData,
            template.schemaClass,
          );
        });

        // Alert the user if any worksheets were not imported
        notImportedWorksheetNames.value = notImported;
        importErrorSnackbar.value = notImported.length > 0;

        // Load imported data
        sampleData.value = imported;

        // Clear validation state
        harmonizerApi.setInvalidCells({});
        invalidCells.value = {};
        Object.keys(tabsValidated.value).forEach((tab) => {
          tabsValidated.value[tab] = false;
        });

        // Sync with backend
        hasChanged.value += 1;
        saveRecord(); // This is a background save that we intentionally don't wait for

        // Load data for active tab into DataHarmonizer
        harmonizerApi.loadData(activeTemplateData.value);

        // Reset the file input so that the same filename can be loaded multiple times
        (document.getElementById('tsv-file-select') as HTMLInputElement).value = '';
      };
      reader.readAsArrayBuffer(file);
    }

    async function changeTemplate(index: number) {
      if (!harmonizerApi.ready.value) {
        return;
      }

      await validate();
      // When changing templates we may need to populate the common columns
      // from the environment tabs
      const nextTemplate = templateList.value[index];
      synchronizeTabData(nextTemplate);

      activeTemplateKey.value = nextTemplate;
      activeTemplate.value = HARMONIZER_TEMPLATES[nextTemplate];
      harmonizerApi.useTemplate(HARMONIZER_TEMPLATES[nextTemplate].schemaClass);
      harmonizerApi.addChangeHook(onDataChange);
    }

    return {
      user,
      APP_HEADER_HEIGHT,
      HELP_SIDEBAR_WIDTH,
      ColorKey,
      HARMONIZER_TEMPLATES,
      columnVisibility,
      harmonizerElement,
      jumpToModel,
      harmonizerApi,
      canSubmit,
      tabsValidated,
      saveRecordRequest,
      submitLoading,
      submitCount,
      selectedHelpDict,
      packageName,
      fields,
      highlightedValidationError,
      sidebarOpen,
      validationItems,
      validationActiveCategory,
      templateList,
      activeTemplate,
      activeTemplateKey,
      invalidCells,
      validationErrors,
      validationErrorGroups,
      validationTotalCounts,
      submissionStatus,
      status,
      submitDialog,
      snackbar,
      schemaLoading,
      importErrorSnackbar,
      notImportedWorksheetNames,
      isTestSubmission,
      /* methods */
      doSubmit,
      downloadSamples,
      errorClick,
      showOpenFileDialog,
      openFile,
      focus,
      jumpTo,
      validate,
      changeTemplate,
      urlify,
      canEditSampleMetadata,
    };
  },
});
