var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",staticStyle:{"transform":"translateY(-50%) rotate(-90deg)","right":"-50px","top":"50%","position":"fixed","z-index":"100"},attrs:{"color":"primary","large":""}},'v-btn',attrs,false),on),[_vm._v(" support "),_c('v-icon',{staticClass:"ml-2",staticStyle:{"transform":"rotate(90deg)"}},[_vm._v(" mdi-message-question ")])],1)]}}])},[_c('ContactCard')],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0 px-0"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('TitleBanner'),_c('IconBar')],1)],1),(_vm.submission.data.results.count === 0)?_c('v-row',[_c('v-col',[_c('IntroBlurb')],1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNewSubmission(false)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Submission ")],1),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.createNewSubmission(true)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Test Submission ")],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"color":"primary"}},on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Test submissions should be used when at a workshop or doing a test, example, or training. These cannot be submitted.")])])],1),_c('v-card-title',{staticClass:"text-h4"},[_vm._v(" Past submissions ")]),_c('v-card-text',[_vm._v(" Pick up where you left off or review a previous submission. ")]),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.submission.data.results.results,"server-items-length":_vm.submission.data.results.count,"options":_vm.options,"loading":_vm.submission.loading.value,"items-per-page":_vm.submission.data.limit,"footer-props":{ itemsPerPageOptions: [10, 20, 50] }},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.submission.data, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.submission.data, "limit", $event)}},scopedSlots:_vm._u([{key:"item.author.name",fn:function(ref){
var item = ref.item;
return [_c('orcid-id',{attrs:{"orcid-id":item.author.orcid,"name":item.author.name,"width":14,"authenticated":true}})]}},{key:"item.templates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metadata_submission.templates.map(function (template) { return _vm.HARMONIZER_TEMPLATES[template].displayName; }).join(' + '))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created).toLocaleString())+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatus(item).color}},[_vm._v(" "+_vm._s(_vm.getStatus(item).text)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function () { return _vm.resume(item); }}},[_vm._v(" Resume "),_c('v-icon',{staticClass:"pl-1"},[_vm._v(" mdi-arrow-right-circle ")])],1),_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function () { return _vm.handleOpenDeleteDialog(item); }}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":550},model:{value:(_vm.isDeleteDialogOpen),callback:function ($$v) {_vm.isDeleteDialogOpen=$$v},expression:"isDeleteDialogOpen"}},[_c('v-card',[_c('v-spacer'),_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delete Submission ")]),_c('v-card-text',{staticClass:"text-h5"},[(_vm.deleteDialogSubmission && _vm.deleteDialogSubmission.study_name != '')?_c('p',[_vm._v(" This will delete \""+_vm._s(_vm.deleteDialogSubmission.study_name)+"\" and all associated data. ")]):_c('p',[_vm._v(" This will delete this submission and all associated data. ")]),_c('p',[_vm._v("This cannot be undone.")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-3",on:{"click":function($event){_vm.isDeleteDialogOpen=_vm.False}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ml-3 white--text",attrs:{"color":"red"},on:{"click":function($event){return _vm.handleDelete(_vm.deleteDialogSubmission)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }