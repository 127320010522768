var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.item !== null)?_c('v-main',[_c('AppBanner'),_c('v-row',{class:{'flex-column': _vm.$vuetify.breakpoint.xs}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-container',[_c('IndividualTitle',{attrs:{"item":_vm.item},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.item.omics_processing_counts)?_c('div',[_vm._l((_vm.item.omics_processing_counts),function(val){return [(val.count && (val.type.toLowerCase() !== 'lipidomics'))?_c('v-chip',{key:val.type,staticClass:"mr-2 my-1",attrs:{"small":""},on:{"click":function($event){return _vm.seeOmicsForStudy(val.type)}}},[_vm._v(" "+_vm._s(_vm.fieldDisplayName(val.type))+": "+_vm._s(val.count)+" ")]):_vm._e()]})],2):_vm._e()]},proxy:true}],null,false,3938072790)}),_c('TeamInfo',{attrs:{"item":_vm.item}})],1),_c('v-col',{attrs:{"offset":"1"}},[_c('div',{staticClass:"display-1"},[_vm._v(" NMDC Details ")]),_c('v-list',[_c('AttributeItem',_vm._b({on:{"click":function($event){return _vm.seeStudyInContext(_vm.item)}}},'AttributeItem',{ item: _vm.item, field: 'id', bindClick: true },false)),_c('AttributeItem',_vm._b({on:{"click":function($event){return _vm.seeStudyInContext(_vm.item)}}},'AttributeItem',{ item: _vm.studyData, field: 'sample_count', bindClick: true },false))],1),(
              _vm.goldLinks.size > 0 ||
                (_vm.item.relevant_protocols && _vm.item.relevant_protocols.length > 0) ||
                _vm.item.principal_investigator_websites.length > 0)?[_c('div',{staticClass:"display-1"},[_vm._v(" Additional Resources ")]),(_vm.goldLinks.size > 0 || (_vm.item.relevant_protocols && _vm.item.relevant_protocols.length > 0) || _vm.item.principal_investigator_websites.length > 0)?_c('v-list',[(_vm.item.relevant_protocols)?_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Protocols ")])],1)],1):_vm._e(),_vm._l(((_vm.item.relevant_protocols || [])),function(proto){return _c('AttributeItem',_vm._b({key:proto,staticStyle:{"padding-left":"60px"}},'AttributeItem',{
                  item: _vm.item,
                  link: { name: proto, target: proto},
                  field: 'relevant_protocols' }
                ,false))}),(_vm.goldLinks.size > 0 || _vm.item.principal_investigator_websites.length > 0)?_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Links ")])],1)],1):_vm._e(),_vm._l((_vm.goldLinks),function(link){return _c('AttributeItem',_vm._b({key:link,staticStyle:{"padding-left":"60px"},attrs:{"image":_vm.images.gold}},'AttributeItem',{
                  item: _vm.item,
                  link: {
                    name: 'GOLD Metadata',
                    target: link
                  }
                },false))}),_vm._l(((_vm.item.principal_investigator_websites || [])),function(site){return [_c('AttributeItem',_vm._b({key:site,staticStyle:{"padding-left":"60px"}},'AttributeItem',{
                    item: _vm.item,
                    link: { name: site, target: site},
                  }
                  ,false))]})],2):_vm._e()]:_vm._e()],2)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[(Object.keys(_vm.item.doi_map).length > 0)?_c('div',{staticClass:"ma-4 pa-2 grey lighten-4"},[(_vm.data.awardDois.length > 0)?[_c('v-subheader',[_vm._v(" Award DOIs ")]),_c('v-list',{staticClass:"transparent"},[_c('v-divider'),_vm._l((_vm.data.awardDois),function(award,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_vm._v(" "+_vm._s(award.cite)+" "),(award.provider)?_c('div',{staticClass:"pt-2"},[_c('span',{staticClass:"font-weight-bold pr-2"},[_vm._v("Provider:")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(award.provider))])]):_vm._e()]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openLink(("https://doi.org/" + (award.id)))}}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Visit site")])])],1)],1)})],2)]:_vm._e(),(_vm.data.publicationDois.length > 0)?[_c('v-subheader',[_vm._v(" Publications ")]),_c('v-divider'),_c('v-list',{staticClass:"\n            transparent"},[_vm._l((_vm.data.publicationDois),function(pub,pubIndex){return [_c('v-list-item',{key:pubIndex},[_c('v-list-item-content',[_vm._v(" "+_vm._s(pub.cite)+" "),(pub.provider)?_c('div',{staticClass:"pt-2"},[_c('span',{staticClass:"font-weight-bold pr-2"},[_vm._v("Provider:")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(pub.provider))])]):_vm._e()]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openLink(("https://doi.org/" + (pub.id)))}}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Visit site")])])],1)],1)]})],2)]:_vm._e(),(_vm.data.datasetDois.length > 0)?[_c('v-subheader',[_vm._v(" Data DOIs ")]),_c('v-list',{staticClass:"transparent"},[_c('v-divider'),_vm._l((_vm.data.datasetDois),function(dataDOI,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_vm._v(" "+_vm._s(dataDOI.cite)+" "),(dataDOI.provider)?_c('div',{staticClass:"pt-2"},[_c('span',{staticClass:"font-weight-bold pr-2"},[_vm._v("Provider:")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(dataDOI.provider))])]):_vm._e()]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openLink(("https://doi.org/" + (dataDOI.id)))}}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Visit site")])])],1)],1)})],2)]:_vm._e()],2):_vm._e(),(_vm.item.part_of && _vm.item.part_of.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"display-1"},[_vm._v(" Part of: ")]),_c('v-list',_vm._l((_vm.parentStudies),function(study){return _c('v-list-item',{key:study.id,attrs:{"to":("" + (study.id))}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(study.annotations.title)+" ")])],1)],1)}),1)],1):_vm._e(),(_vm.item.children && _vm.item.children.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"display-1"},[_vm._v(" Associated Studies: ")]),_c('v-list',_vm._l((_vm.item.children),function(study){return _c('v-list-item',{key:study.id,attrs:{"to":("" + (study.id))}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(study.annotations.title)+" ")])],1)],1)}),1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }